import React from 'react';

import { Layout } from '../components/common';
import { MetaData } from '../components/common/meta';
import { FinancialToolsOfferWall } from '../components/FinancialToolsOfferWall/FinancialToolsOfferWall';
import { Hero } from '../components/InnerPages/Hero';
import CreditCards from '../images/CreditCards';

import style from '../styles/pages/extra-money.scss';

import { Helmet } from 'react-helmet';

interface ICreditCardsBadCreditProps {
  location: any;
}

const CreditCardsBadCredit = ({ location }: ICreditCardsBadCreditProps) => {
  return (
    <>
      <Helmet>
        <script type="text/javascript">{`
       var _clcktt = _clcktt || {};
        _clcktt['cIdDesktop'] = "68659";
        _clcktt['cIdMobile'] = "203932";
        (function() {
            var ca = document.createElement('script'); ca.type = 'text/javascript';
            ca.async = true;
            ca.src = '//revpie.com/api/js/extractor.js';
            var sc = document.getElementsByTagName('script')[0]; sc.parentNode.insertBefore(ca, sc);
        })();
      `}</script>
        <style>{`
        .container {
          background-color: initial !important;
        }

        main > div {
          background-color: #fff;
        }
      `}</style>
      </Helmet>
      <MetaData data={{}} location={location} title="Credit Cards for Any Credit" />
      <Layout headerClassName={style.header} mainClass={style.main}>
        <Hero
          className={style.hero}
          icon={<CreditCards />}
          title="Credit Cards for Any Credit"
          description="Compare credit cards from our partners, view offers and apply online for the card that is the best fit for you."
        />
        <div className={style.content}>
          <div className="container">
            <div id="clcktt"></div>
            <FinancialToolsOfferWall
              items={[
                {
                  title: 'Credit Score for Beginners',
                  link: '/build-credit/',
                },
                {
                  title: 'Learn More About Credit',
                  link: '/tag/credit/',
                },
                {
                  title: 'More Credit Cards',
                  link:
                    'https://www.cardratings.com/bestcards?&CCID=20372536204626483&QTR=ZZf201611071631130Za20372536Zg255Zw0Zm0Zc204626483Zs7273ZZ&CLK=213241108105128343&src=693887&&exp=y',
                },
              ]}
            />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default CreditCardsBadCredit;
